<template>
  <div class="account-two-factor" :class="{ 'is-bionic': bionicSwitcher }">
    <template v-if="code && code.length > 0">
      <hr/>
      <div class="d-flex">
        <strong class="mr-auto font-weight-bold">Authenticator 2FA Codes:</strong>
        <div class="ml-0 pl-0 mt-1 mr-0">
          <p-progress-bar :value="(code[0].nSeconds / 30) * 100"
                          :class="['border', `border-${alertLevel(code[0])}`]"
                          style="width: 60px; height: 1rem;"
                          :dt="{ value: { background: alertLevelBackgroundDesignToken(code[0]) } }"
                          aria-valuemax="30"
                          :showValue="false" />
        </div>
      </div>
      <div v-for="(c, i) in code"
           :key="i"
           class="d-flex-center">
        <div v-p-tooltip.left="{ value: `${c.name} &mdash; \n${c.service}`, escape: false, showDelay: 500, hideDelay: 100 }"
             class="white-space-no-wrap overflow-hidden text-overflow-ellipsis flex-grow-1"
             :class="{ 'small': !bionicSwitcher}">{{c.service}}</div>
        <div class="white-space-no-wrap">
          <b-btn :key="c.authCode"
                 variant="link"
                 size="sm"
                 class="px-0 d-flex-center"
                 :class="`text-${alertLevel(c)}`"
                 v-click-copy="c.authCode">
            <span class="mr-2 text-monospace">{{c.authCode}}</span>
            <fluency-icon type="copy" :class="alertLevel(c) === 'danger' ? 'invisible' : `text-${alertLevel(c)}`" />
          </b-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { setActiveInterval } from 'core-ui/utils/setActiveInterval'
export default {
  name: 'AccountTwoFactor',
  data () {
    return {
      code: null,
      seconds: 0
    }
  },
  async mounted () {
    await this.fetch2Fa()
    this.polling2FaInterval = setActiveInterval(this.fetch2Fa, 1000)
  },
  unmounted () {
    this.clearPollingInterval()
  },
  computed: {
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    bionicSwitcher () {
      return this.$store.getters?.appForksBionicLayout
    }
  },
  methods: {
    clearPollingInterval () {
      clearInterval(this.polling2FaInterval)
    },
    alertLevel (tfa) {
      if (tfa.nSeconds < 3) {
        return 'danger'
      } else if (tfa.nSeconds < 10) {
        return 'warning'
      }
      return 'info'
    },
    alertLevelBackgroundDesignToken (tfa) {
      if (tfa.nSeconds < 3) {
        return '{danger.color}'
      } else if (tfa.nSeconds < 10) {
        return '{warning.color}'
      } else {
        return '{info.color}'
      }
    },
    async fetch2Fa () {
      const d = new Date()
      const seconds = d.getSeconds()
      if (!this.code || seconds % 30 < 1) {
        const response = await this.$res.fetch.fetch2Fa()
        if (response) {
          this.$setCompat(this, 'code', response)
        }
      } else if (this.code) {
        this.code.forEach(c => {
          c.nSeconds = 30 - seconds % 30
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.account-two-factor {
  min-width: 250px;
  max-width: 250px;

  &.is-bionic {
    min-width: 300px;
    max-width: 300px;
  }
}
</style>
