<template>
  <div class="d-flex-center">
    <b-checkbox :checked="toggleValueBool"
                switch
                @change="toggleValueBool = $event; toggleEmail($event)">Allow Emails from Fluency</b-checkbox>
  </div>
</template>

<script>
export default {
  name: 'userCanEmailSwitcher',
  props: {
    variant: {
      type: String,
      default: 'success'
    }
  },
  data () {
    return {
      toggleValueBool: false
    }
  },
  watch: {
    'user.canEmail': {
      handler (val) {
        this.$setCompat(this, 'toggleValueBool', val)
      },
      immediate: true
    }
  },
  computed: {
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    }
  },
  methods: {
    toggleEmail (val) {
      this.$store.commit('user', { ...this.user, canEmail: val })
      this.setUser({ ...this.user, canEmail: val })
    },
    async setUser (payload) {
      if (payload) {
        const response = await this.$res.set.user(payload)
        if (response) {
          const message = (response.canEmail) ? 'You will now receive emails from Fluency.' : 'You will no longer receive emails from Fluency.'
          this.$toast(message, (response.canEmail) ? 'success' : 'warning')
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
