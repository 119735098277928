<template>
  <div :class="classes.container" class="user-select-none">
    <div :class="[classes.nav, {'is-local-mode': runningLocal, 'is-record-mode': recording }]">
      <div class="d-flex flex-column">
        <!-- ------------------------------------------------------------------------------------------------------- -->
        <!-- TOP SECTION ------------------------------------------------------------------------------------------- -->
        <!-- ------------------------------------------------------------------------------------------------------- -->
        <div :class="classes.navTop">
          <!-- LOGO -->
          <div class="d-flex-center-center">
            <div :id="`${popover.targetId}-logo`"
                 class="brand-logo position-relative"
                 :class="classes.button">
              <nuxt-link to="/" class="w-100" :data-qa="`${qa.primary}-home`">
                <img src="/assets/logo/logo-small.svg" @click="trackEvent(tracking.topLevel, { name: 'homepage', action: 'click' })" />
              </nuxt-link>
              <span v-if="internalShowNeedsRefresh" class="red-dot"></span>
            </div>
          </div>
          <div class="top-section-spacer brand-to-search"></div>
          <tasty-popover v-if="showTooltipOnLogo"
                         id="bionic-nav-logo"
                         :custom-class="classes.popover"
                         :target="`${popover.targetId}-logo`"
                         :delay="popover.delay"
                         :triggers="popover.triggers.logo"
                         :boundary-padding="popover.boundaryPadding.logo"
                         :placement="popover.placement.logo">
            <div :class="classes.menuContainer">
              <ul :class="classes.ul">
                <li class="m-2">
                  <b-btn :variant="variant.menuItem"
                         @click="reloadPlatform()">
                    <fluency-icon type="reload" class="mr-2" /> Reload Platform
                  </b-btn>
                </li>
              </ul>
            </div>
          </tasty-popover>

          <!-- SEARCH -->
          <div>
            <t-button-inline class="search-icon"
                             :class="classes.button"
                             v-p-tooltip="{ value: 'Jump to a Page\n(cmd + shift + j)', escape: false, showDelay: 250 }"
                             :data-qa="`${qa.primary}-search`"
                             @click.prevent="openSearch()">
              <div :class="classes.link">
                <fluency-icon type="search" :class="classes.linkIcon" />
              </div>
            </t-button-inline>
          </div>
          <div class="top-section-spacer search-to-links"></div>

          <!-- MANAGE, AUTOMATE, INSIGHTS -------------------------------------------------------------------------- -->
          <template v-for="(navItem, navKey) in navTreeTop"
                    :key="navKey">
            <t-button-inline :id="`${popover.targetId}-${navItem.value}`"
                             :class="[classes.button, { 'active': activeHoverOver === navItem.value }]"
                             :data-qa="`${qa.primary}-${navItem.value}`"
                             @mouseover="setActiveHover(navItem.value)"
                             @click.prevent>
              <div :class="[classes.link, { 'active': activeHoverOver === navItem.value }]">
                <fluency-icon :type="navItem.icon"
                              :class="[classes.linkIcon, { 'selected': navItem.isActive }]" />
              </div>
              <div :class="[classes.buttonLabel, { 'selected': navItem.isActive }]">{{ navItem.label }}</div>
            </t-button-inline>
            <tasty-popover :id="`bionic-nav-${navItem.value}`"
                           :custom-class="classes.popover"
                           :target="`${popover.targetId}-${navItem.value}`"
                           :delay="popover.delay"
                           :triggers="popover.triggers.upper"
                           :boundary-padding="popover.boundaryPadding.default"
                           :placement="popover.placement.upper">
              <div :class="classes.menuContainer">
                <!--<h4 class="bionic-popover-heading">{{(navItem.label) ? navItem.label : 'Fluency'}}</h4>-->

                <ul :class="classes.ul">
                  <li v-for="(subnav, subnavKey) in navItem.subNav"
                      :key="subnavKey">
                    <template v-if="subnav.items && subnav.items.length > 0">
                      <h6 :class="classes.menuHeading">
                        {{subnav.label}}
                        <span class="flex-grow-1 border-bottom ml-2"></span>
                      </h6>
                      <ul :class="classes.subUl">
                        <li v-for="(subnavItem, subnavItemKey) in subnav.items"
                            :key="`${subnavKey}-${subnavItemKey}`"
                            @click="popoverLinkClick(`bionic-nav-${navItem.value}`, subnavItem)">
                          <nuxt-link :to="subnavItem.link"
                                     :class="[classes.menuItem, { 'selected': isLinkActive(subnavItem.link) }]"
                                     :data-qa="`${qa.popover}-${subnavItem.qa}`">{{subnavItem.text}}</nuxt-link>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </div>
            </tasty-popover>
            <div class="top-section-spacer"></div>
          </template>

        </div>

        <!-- ------------------------------------------------------------------------------------------------------- -->
        <!-- BOTTOM SECTION ---------------------------------------------------------------------------------------- -->
        <!-- ------------------------------------------------------------------------------------------------------- -->
        <div :class="classes.navBottom">
          <template v-if="user && user.capabilities && user.capabilities.RecordMode && recording">
            <div class="d-flex-center-center my-4">
              <user-recording :bionic="true" />
            </div>
          </template>

          <div :data-qa="`${qa.primary}-customerSwitcher`">
            <user-customer-selector v-if="user && user.capabilities && user.capabilities.CustomerSwitcher"
                                    :bionic="true"
                                    :popover="popover" />
          </div>
          <div class="bottom-section-spacer"></div>

          <!-- SETTINGS -->
          <t-button-inline :id="`${popover.targetId}-settings`"
                           :data-qa="`${qa.primary}-settings`"
                           :class="[classes.button, { 'active': activeHoverOver === 'settings' }]"
                           @mouseover="setActiveHover('settings')"
                           @click.prevent>
            <div :class="classes.link">
              <fluency-icon type="settings" :class="classes.linkIcon" />
            </div>
          </t-button-inline>
          <div class="bottom-section-spacer"></div>

          <!-- HELP -->
          <t-button-inline :id="`${popover.targetId}-help`"
                           :data-qa="`${qa.primary}-help`"
                           :class="[classes.button, { 'active': activeHoverOver === 'help' }]"
                           @mouseover="setActiveHover('help')"
                           @click.prevent>
            <div :class="classes.link">
              <fluency-icon type="help" :class="classes.linkIcon" />
            </div>
          </t-button-inline>
          <div class="bottom-section-spacer"></div>

          <!-- USER -->
          <t-button-inline :id="`${popover.targetId}-user`"
                           :data-qa="`${qa.primary}-user`"
                           :class="[classes.button, { 'active': activeHoverOver === 'user' }]"
                           @mouseover="setActiveHover('user')"
                           @click.prevent>
            <div :class="classes.link">
              <fluency-icon type="user" :class="classes.linkIcon" />
            </div>
          </t-button-inline>

          <!-- ----------------------------------------------------------------------------------------------------- -->
          <!-- POPOVERS -------------------------------------------------------------------------------------------- -->
          <!-- ----------------------------------------------------------------------------------------------------- -->
          <div>
            <!-- --------------------------------------------------------------------------------------------------- -->
            <!-- SETTINGS ------------------------------------------------------------------------------------------ -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <tasty-popover :id="`${popover.popoverId}-settings`"
                           :custom-class="classes.popover"
                           :target="`${popover.targetId}-settings`"
                           :delay="popover.delay"
                           :triggers="popover.triggers.lower"
                           :boundary-padding="popover.boundaryPadding.default"
                           :placement="popover.placement.lower"
                           :resize-helper="true">
              <div :class="classes.menuContainer">
                <ul :class="classes.ul">
                  <li v-if="navTreeSettings">
                    <ul v-for="(navItem, navItemKey) in navTreeSettings"
                        :key="navItemKey"
                        :class="classes.subUl">
                      <template v-for="(subNav, subNavKey) in navItem.subNav"
                                :key="subNavKey">
                        <li v-if="subNav.items && subNav.items.length > 0">
                          <h4 :class="classes.menuHeading">
                            {{subNav.label}}<span class="flex-grow-1 border-bottom ml-3"></span>
                          </h4>

                          <ul :class="classes.subUl">
                              <li v-for="(linkItem, linkItemKey) in subNav.items"
                                  :key="linkItemKey"
                                  class="m-2"
                                  @click="popoverLinkClick(`${popover.popoverId}-settings`, linkItem)">
                                <nuxt-link :to="linkItem.link"
                                           :data-qa="`${qa.popover}-${linkItem.qa}`"
                                           :class="classes.menuItem">
                                  <fluency-icon v-if="linkItem.icon"
                                                :type="linkItem.icon"
                                                class="mr-2" />
                                  {{linkItem.text}}
                                </nuxt-link>
                              </li>
                          </ul>
                        </li>
                      </template>
                    </ul>
                  </li>
                </ul>
              </div>
            </tasty-popover>

            <!-- --------------------------------------------------------------------------------------------------- -->
            <!-- SUPPORT ------------------------------------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <tasty-popover :id="`${popover.popoverId}-help`"
                           :custom-class="classes.popover"
                           :target="`${popover.targetId}-help`"
                           :delay="popover.delay"
                           :triggers="popover.triggers.lower"
                           :boundary-padding="popover.boundaryPadding.default"
                           :placement="popover.placement.lower"
                           :resize-helper="true">
              <div :class="classes.menuContainer">
                <ul :class="classes.ul">
                  <li>
                    <h4 :class="classes.menuHeading">
                      Fluency Help
                      <span class="flex-grow-1 border-bottom ml-3"></span>
                    </h4>

                    <ul :class="classes.subUl">
                      <li class="m-2"
                          @click="popoverLinkClick(`${popover.popoverId}-help`, { tracking: 'helpCenter' })">
                        <a href="http://help.fluency.inc/"
                           target="_blank"
                           :data-qa="`${qa.popover}-helpCenter`"
                           :class="classes.menuItem">
                          <fluency-icon type="linkOut" class="mr-2" /> Help Center
                        </a>
                      </li>
                      <li v-if="whatFixEnabled"
                          class="m-2"
                          @click="popoverLinkClick(`${popover.popoverId}-help`, { tracking: 'qa' })">
                        <fluency-support :inline="true" :data-qa="qa.popover" />
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </tasty-popover>

            <!-- --------------------------------------------------------------------------------------------------- -->
            <!-- USER ---------------------------------------------------------------------------------------------- -->
            <!-- --------------------------------------------------------------------------------------------------- -->
            <tasty-popover :id="`${popover.popoverId}-user`"
                           :custom-class="classes.popover"
                           :target="`${popover.targetId}-user`"
                           :delay="popover.delay"
                           :triggers="popover.triggers.lower"
                           :boundary-padding="popover.boundaryPadding.default"
                           :placement="popover.placement.lower"
                           :resize-helper="true">
              <div class="d-flex-center-end">
                <template v-if="user && user.capabilities && user.capabilities.RecordMode">
                  <user-recording :bionic="true" />
                </template>
              </div>
              <div :class="classes.menuContainer">
                <ul :class="classes.ul">
                  <li class="my-2">
                    <h4 :class="classes.menuHeading">
                      {{user.given_name}} {{user.family_name}} ({{user.userName}})
                      <em v-if="readOnly" class="text-muted">(read-only)</em>
                    </h4>
                  </li>
                  <li class="my-2">{{user.userName}}</li>
                </ul>

                <ul :class="classes.ul">
                  <li v-if="oktaUserId" class="my-3 mx-0 pb-4">
                    <t-button-inline @click="mfaEnrollClick">Manage MFA Login for Backpack</t-button-inline>
                  </li>
                </ul>

                <ul :class="classes.ul">
                  <li>
                    <h4 :class="classes.menuHeading">
                      <span class="flex-grow-1 border-bottom ml-3"></span>
                    </h4>
                  </li>
                  <li class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-setDefaultHomepage`"
                           class="d-flex-start"
                           @click="setDefaultLandingPageToCurrent">
                      <fluency-icon :type="isDefaultLandingPage ? 'star' : 'emptyStar'"
                                    class="mr-2"
                                    :class="{'active':isDefaultLandingPage, 'landing-page-control':true}" />
                      <span class="ml-2">
                        {{getDefaultLandingPageTooltipText()}}
                      </span>
                    </b-btn>
                  </li>
                  <li class="m-2" @click.stop="popoverLinkClick(`${popover.popoverId}-user`, { tracking: 'visibilityPreferences' })">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-visibilityPreferences`"
                           @click.prevent="userPersonalModal()">
                      <fluency-icon type="preview" class="mr-2" />
                      Visibility Preferences
                    </b-btn>
                  </li>
                  <li class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-reloadPlatform`"
                           @click="reloadPlatform()">
                      <fluency-icon type="reload" class="mr-2" /> Reload Platform
                    </b-btn>
                  </li>
                  <li v-if="isInternalUser" class="m-2">
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-switchToExternalUser`"
                           @click="switchToExternalUser()">
                      <fluency-icon type="logout" class="mr-2" />
                      Move to External User
                    </b-btn>
                  </li>
                  <li class="m-2"
                      :data-qa="`${qa.popover}-userModeSwitcher`"
                      @click.stop>
                    <user-mode-switcher />
                  </li>
                  <li class="m-2"
                      :data-qa="`${qa.popover}-performanceBounds`"
                      @click.stop>
                    <guardrail-mode-switcher />
                  </li>
                  <li class="m-2"
                      :data-qa="`${qa.popover}-allowEmailsFromFluency`"
                      @click.stop>
                    <user-can-email-switcher />
                  </li>
                </ul>

                <ul :class="classes.ul">
                  <li :data-qa="`${qa.popover}-accountTwoFactor`"
                      @click.stop>
                    <account-two-factor />
                  </li>
                </ul>

                <ul :class="classes.ul" class="py-2 mt-4 border-top">
                  <li v-if="eligibleForBionicSwitcher">
                    <b-btn :variant="variant.menuItem"
                           @click="toggleBionicLayout()">
                      <fluency-icon type="fork" class="mr-2" /> Back to Original Layout
                    </b-btn>
                  </li>
                  <li v-if="eligibleForSettingsSwitcher">
                    <b-btn :variant="variant.menuItem"
                           @click="toggleSettingsFork()">
                      <fluency-icon type="fork" class="mr-2" />
                      <template v-if="appForkSettings">Back to Old Settings Layout</template>
                      <template v-else>Try New Settings Layout</template>
                    </b-btn>
                  </li>
                </ul>
                <ul :class="classes.ul" class="py-2 border-top">
                  <li>
                    <b-btn :variant="variant.menuItem"
                           :data-qa="`${qa.popover}-logout`"
                           @click.prevent="logout()">
                      <fluency-icon type="logout" class="mr-2" /> Sign Out
                    </b-btn>
                  </li>
                </ul>
              </div>
            </tasty-popover>
          </div>
        </div>
      </div>
    </div>

    <url-modal></url-modal>
    <jumper2 />
    <mfa-enroll v-model="mfaEnrollOpen" />
    <user-persona-configuration :value="showModalUserPersonaSettings"
                                @hide-modal="userPersonalModal(false)" />
  </div>
</template>

<script>
import UserCustomerSelector from '@/components/common/userCustomerSelector'
import FluencySupport from '@/components/common/fluencySupport'
import AccountTwoFactor from '@/components/common/accountTwoFactor'
import { useServiceWorker } from '../../composables/useServiceWorker.js'
import MfaEnroll from '@/components/common/mfaEnroll'
import UserModeSwitcher from '@/components/common/userModeSwitcher'
import UserCanEmailSwitcher from '@/components/common/userCanEmailSwitcher'
import UserPersonaConfiguration from '@/components/common/userPersonaConfiguration'
import GuardrailModeSwitcher from '@/components/common/guardrailModeSwitcher'
import UserRecording from '~/components/common/userRecording.vue'
import UrlModal from '@/components/common/urlModal'
import Jumper2 from '~/components/common/jumper2.vue'
import _uniq from 'lodash.uniq'
import TButtonInline from 'core-ui/components/tasty/tastyButtonInline'

export default {
  name: 'bionicNavigation',
  props: {},
  components: {
    TButtonInline,
    UserCustomerSelector,
    FluencySupport,
    AccountTwoFactor,
    MfaEnroll,
    UserModeSwitcher,
    UserCanEmailSwitcher,
    UserPersonaConfiguration,
    GuardrailModeSwitcher,
    UserRecording,
    UrlModal,
    Jumper2
  },
  setup () {
    const { isWaiting, deleteRuntimeCache } = useServiceWorker()

    return {
      isWaiting,
      deleteRuntimeCache
    }
  },
  created () {
    if (this.$store.getters.isLocalDev) {
      this.runningLocal = true
    }
  },
  data () {
    return {
      debug: false,
      runningLocal: false,
      navPanelOpen: false,
      activePrimaryNavItem: null,
      classes: {
        container: 'bionic-nav-container',
        nav: 'bionic-nav',
        navTop: 'bionic-nav-top',
        navBottom: 'bionic-nav-bottom',
        button: 'bionic-nav-button d-flex-center-center flex-column p-0 no-focus position-relative',
        buttonLabel: 'bionic-nav-button-label position-absolute bottom-0',
        link: 'bionic-nav-link d-flex-center-center',
        linkIcon: 'bionic-nav-link-icon',
        popover: 'bionic-nav-popover',
        menuContainer: 'bionic-nav-menu-container',
        menuHeading: 'bionic-nav-menu-heading d-flex-center-center',
        ul: 'bionic-nav-menu list-unstyled mb-0',
        subUl: 'bionic-nav-sub-menu list-unstyled py-2',
        menuItem: 'btn btn-bionic-nav-menu-item'
      },
      variant: {
        menuItem: 'bionic-nav-menu-item'
      },
      qa: {
        primary: 'navigation-primaryLink',
        popover: 'navigation-popoverLink'
      },
      tracking: {
        category: 'navigation',
        topLevel: 'bionic-nav-top-level',
        subNav: 'bionic-nav-sub',
        search: 'bionic-nav-search'
      },
      mfaEnrollOpen: false,
      showModalUserPersonaSettings: false,
      hasPanelAltContent: false,
      shownPopovers: [],
      activeHoverOver: null
    }
  },
  computed: {
    debugMode () {
      return this.debug && this.isInternalUser
    },
    popover () {
      // there are multiple values here for debugging
      return {
        targetId: 'bionic-popover-target',
        popoverId: 'bionic-popover-id',
        triggers: {
          logo: this.debugMode ? 'click hover focus' : 'click hover focus',
          upper: this.debugMode ? 'click hover focus' : 'click hover focus',
          customerSwitcher: this.debugMode ? 'click hover focus' : 'click hover focus',
          lower: this.debugMode ? 'click hover focus' : 'click hover focus'
        },
        delay: { show: 300, hide: 300 },
        boundaryPadding: {
          logo: 16,
          default: 0
        },
        placement: {
          logo: 'rightbottom',
          upper: 'rightbottom',
          lower: 'rightbottom'
        }
      }
    },
    navigationForUser () {
      return this.$store.getters?.navigationForUser?.navigation || []
    },
    navTree () {
      const tree = []

      tree.push({
        value: 'manage',
        label: 'Manage',
        icon: 'globe',
        section: 'top',
        isActive: !!this.activeSections.find(sec => sec.top === 'manage'),
        subNav: [
          {
            value: 'portfolio',
            label: 'Portfolio',
            items: this.subNavItems?.manage?.portfolio || []
          },
          {
            value: 'bulkManage',
            label: 'Bulk',
            items: this.subNavItems?.manage?.bulkManage || []
          },
          {
            value: 'tools',
            label: 'Manage Tools',
            items: this.subNavItems?.manage?.tools || []
          }
        ]
      })
      tree.push({
        value: 'automate',
        label: 'Automate',
        icon: 'rocket',
        section: 'top',
        isActive: !!this.activeSections.find(sec => sec.top === 'automate'),
        subNav: [
          {
            value: 'blueprints',
            label: 'Blueprints',
            items: this.subNavItems?.automate?.blueprints || []
          },
          {
            value: 'launch',
            label: 'Launch',
            items: this.subNavItems?.automate?.launch || []
          },
          {
            value: 'blueprintData',
            label: 'Blueprint Data',
            items: this.subNavItems?.automate?.blueprintData || []
          },
          {
            value: 'automationTools',
            label: 'Automation Tools',
            items: this.subNavItems?.automate?.automationTools || []
          }
        ]
      })
      tree.push({
        value: 'insights',
        label: 'Insights',
        icon: 'graph',
        section: 'top',
        isActive: !!this.activeSections.find(sec => sec.top === 'insights'),
        subNav: [
          {
            value: 'reportingTools',
            label: 'Reporting Tools',
            items: this.subNavItems?.insights?.reportingTools || []
          },
          {
            value: 'monitor',
            label: 'Monitor',
            items: this.subNavItems?.insights?.monitor || []
          }
        ]
      })
      tree.push({
        value: 'settings',
        label: 'Settings',
        icon: 'settings',
        section: 'settings',
        // isActive: !!this.activeSections.find(sec => sec.top === 'settings'),
        subNav: [
          {
            value: 'globalSettings',
            label: 'Global Settings',
            items: this.subNavItems?.settings?.globalSettings || []
          },
          {
            value: 'internalTools',
            label: 'Internal Tools',
            items: this.subNavItems?.settings?.internalTools || []
          }
        ]
      })

      return tree
    },
    navTreeTop () {
      return this.navTree.filter(n => n.section === 'top')
    },
    navTreeSettings () {
      return this.navTree.filter(n => n.section === 'settings')
    },
    subNavItems () {
      const o = {}

      this.navigationForUser.forEach(navItem => {
        if (Array.isArray(navItem.placements)) {
          navItem.placements.forEach(placement => {
            if (!o.hasOwnProperty(placement.top)) {
              o[placement.top] = {}
            }
            if (!o[placement.top].hasOwnProperty(placement.sub)) {
              o[placement.top][placement.sub] = []
            }

            o[placement.top][placement.sub].push(navItem)
          })
        }
      })

      return o
    },
    activeNavNode () {
      this.initActiveNavNode()
      return this.navTree.find(item => item.value === this.activePrimaryNavItem)
    },
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    isInternalUser () {
      return this.user?.internalUser
    },
    readOnly () {
      return (this.user.roles || []).includes('read_only')
    },
    recording () {
      return this.user?.recordMode
    },
    oktaUserId () {
      return this.$store.getters.oktaUserId
    },
    capabilities () {
      return this?.user?.capabilities
    },
    channels () {
      return this.$store.getters.advertisingChannels
    },
    eligibleForBionicSwitcher () {
      return true
    },
    activeSections () {
      const fullPathname = this.$route.fullPath

      const matches = this.navigationForUser.find(navItem => fullPathname === navItem.link)

      return matches?.placements || []
    },
    activeLinks () {
      const fullPathname = this.$route.fullPath

      const matches = this.navigationForUser.filter(navItem => fullPathname === navItem.link)

      return matches || []
    },
    whatFixEnabled () {
      return this.user?.capabilities?.WhatFix
    },
    showTooltipOnLogo () {
      return this.internalShowNeedsRefresh
    },
    internalShowNeedsRefresh () {
      return this.isWaiting && this.isInternalUser
    },
    userAndChannels () {
      return {
        user: this.user,
        channels: this.channels
      }
    },
    appForkSettings () {
      return this.$store.getters.appForkSettings
    },
    eligibleForSettingsSwitcher () {
      const whitelist = ['greg@fluencyinc.co', 'matt@fluencyinc.co', 'jeff@fluency.inc', 'amy@fluency.inc', 'jeffrey@fluencyinc.co', 'adam@fluency.inc', 'michelle@fluencyinc.co', 'claire@fluency.inc', 'dan.sonneborn@fluency.inc', 'eric@fluency.inc', 'scott@fluencyinc.co', 'ali@fluencyinc.co']
      return this.isInternalUser && whitelist.includes(this.user?.email)
    },
    defaultHomePage () {
      return this.user.defaultHomePage || '/manage/'
    },
    isDefaultLandingPage () {
      const homePageRoute = this.$router.resolve(this.defaultHomePage)
      if (homePageRoute?.name?.length > 0) {
        return this.$route.fullPath.startsWith(this.defaultHomePage)
      }
      return false
    }
  },
  mounted () {
    this.$root.$on('bv::popover::show', bvEventObj => {
      this.logShownPopover(bvEventObj)
      this.closeAnyOpenPopovers(bvEventObj?.componentId)
      this.trackPopover(bvEventObj)
    })
    this.$root.$on('bv::popover::hide', bvEventObj => {
      this.setActiveHover()
    })
  },
  updated () {
    this.closeAnyOpenPopovers()
  },
  watch: {
    userAndChannels: {
      immediate: true,
      deep: true,
      handler () {
        this.$store.dispatch('trimNavForUser')
      }
    }
  },
  methods: {
    initActiveNavNode () {
      if (!this.activePrimaryNavItem) {
        this.activePrimaryNavItem = this.activeSections?.[0]?.top
      }
    },
    setActiveHover (e) {
      this.activeHoverOver = e || null
    },
    toggleBionicLayout () {
      this.$store.dispatch('toggleForkedUiElement', { val: null, key: 'nav' })
    },
    toggleSettingsFork () {
      this.$store.commit('toggleForkedUiElement', { val: !this.appForkSettings, key: 'settings' })
    },
    popoverLinkClick (popoverDd = null, navItem = null) {
      if (popoverDd) {
        this.$root.$emit('bv::hide::popover', popoverDd)
      }
      if (navItem?.tracking) {
        this.trackEvent(this.tracking.subNav, { name: navItem.tracking, action: 'click' })
      }
    },
    paramsToObject (entries) {
      const result = {}
      for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value
      }
      return result
    },
    isLinkActive (item) {
      return !!this.activeLinks.find(link => link.link === item)
    },
    async logout () {
      if (this.$authn.isAuthenticated()) {
        await this.$authn.logout()
      }
    },
    async reloadPlatform () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          capabilities: response.capabilities
        }

        await Promise.all([
          this.$store.commit('user', newUser)
        ])
      }

      ['verticals', 'skeletons', 'userSavedSegments', 'savedSegmentFields', 'enumerations', 'reportDefinitions', 'partners', 'advertisingChannels'].forEach(cacheKey => {
        localStorage.removeItem(cacheKey)
      })

      await this.deleteRuntimeCache()

      window.location.reload(true) // this non-standard param is for Firefox
    },
    mfaEnrollClick () {
      this.popoverLinkClick(`${this.popover.popoverId}-user`)
      this.mfaEnrollOpen = true
    },
    switchToExternalUser () {
      const newUser = {
        ...this.user,
        internalUser: false
      }

      this.$store.commit('user', newUser)

      this.popoverLinkClick(`${this.popover.popoverId}-user`)
    },
    userPersonalModal (val = true) {
      this.showModalUserPersonaSettings = val
    },
    openSearch () {
      this.trackEvent(this.tracking.topLevel, { name: 'search', action: 'click' })
      this.$eventBus.$emit('openAccountJump')

      this.closeAnyOpenPopovers()
    },
    getDefaultLandingPageTooltipText () {
      if (this.isDefaultLandingPage) {
        // return 'This is your home page. To change it, navigate to your desired home page and click this button.'
        return 'This is your default page'
      } else {
        // return 'Click to set this page as your default home page'
        return 'Set as default page'
      }
    },
    setDefaultLandingPageToCurrent () {
      this.$res.set.userHomePage(this.$route.path)
      this.$store.commit('userDefaultHomePage', this.$route.path)
      this.$toast(`Default Home Page set to ${this.$route.path}`, 'success')
    },
    closeAnyOpenPopovers (idToIgnore = null) {
      this.shownPopovers.forEach(po => {
        if (po && (!idToIgnore || po !== idToIgnore)) {
          this.$root.$emit('bv::hide::popover', po)
        }
      })
      if (!idToIgnore) {
        this.shownPopovers = []
      }
    },
    logShownPopover (bvEventObj) {
      if (!bvEventObj?.componentId?.startsWith('bionic-nav')) {
        return
      }

      const copyArr = [...this.shownPopovers]
      copyArr.push(bvEventObj?.componentId)
      this.shownPopovers = _uniq(copyArr)
    },
    trackPopover (popover) {
      let name = popover?.componentId || ''
      if (popover?.componentId.indexOf('bionic-nav-') >= 0) {
        name = name.replace('bionic-nav-', '')
        this.trackEvent(this.tracking.topLevel, { name, action: 'popover-shown' })
      }
      if (popover?.componentId.indexOf('bionic-popover-id-') >= 0) {
        name = name.replace('bionic-popover-id-', '')
        this.trackEvent(this.tracking.topLevel, { name, action: 'popover-shown' })
      }
    },
    trackEvent (name, meta) {
      // name, meta, category, element
      this.$track.event(name, meta, this.tracking.category)
    }
  }
}
</script>

<style scoped lang="scss">
.bionic-nav-container {
  display: flex;
  height: 100%;
}
.bionic-nav {
  display: grid;
  grid-auto-rows: 1fr;
  grid-auto-columns: 64px;
  text-align: center;
  background-color: $bionic-nav-bg;
  border-right: 1px solid $bionic-300;

  &.is-local-mode {
    background-color: #EEF4FF !important;
  }
  &.is-record-mode {
    background-color: lighten($fluency-red, 32) !important;
  }

  .red-dot {
    position: absolute;
    top: 2px;
    right: 18px;
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: $fluency-red;
    color: $fluency-red;
  }
}
.current {
  border-bottom: 1px solid $fluency-green;
  outline: none;
  box-shadow: none;
}

.bionic-nav-link-icon.selected {
  color: #6673FF;
}

.bionic-nav-button {
  width: 100%;
}

.bionic-nav-link {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  color: $bionic-700;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  //border: 1px solid red;

  &.active,
  &:hover {
    background-color: $bionic-200;
    background-color: #E0EAFF;
    color: $bionic-700;
    transition: background-color 0.25s ease-in, color 0.25s ease-in;

    .bionic-nav-link-icon {
      color: #444CE7;
    }
  }
  .bionic-nav-link-icon {
    width: 24px;
    padding-top: 4px; // needed for centering
  }
}

.bionic-nav-top {
  -webkit-box-flex: 1 !important;
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;

  .bionic-nav-button {
    text-decoration: none;
    padding-bottom: 18px !important;

    &.brand-logo {
      padding-bottom: 0 !important;
      min-height: 32px;
    }

    &.search-icon {
      padding-bottom: 0 !important;
    }
  }

  .bionic-nav-button-label {
    font-size: 11px;
    font-weight: 600;
    color: #5D6B98;
    //border: 1px solid blue;
    &.selected {
      color: #6673FF;
    }
  }

  .bionic-nav-button.active {
    .bionic-nav-button-label {
      color: #444CE7;
    }
  }

  .top-section-spacer {
    height: 24px;

    &.brand-to-search {
      height: 30px;
    }
    &.search-to-links {
      height: 36px;
    }
  }
}
.bionic-nav-bottom {
  .bottom-section-spacer {
    height: 24px;
  }
}

.bionic-nav-top,
.bionic-nav-bottom {
  padding-top: 24px;
  padding-bottom: 24px;
}
</style>

<style lang="scss">/* not scoped because we need to apply styles inside tastyPopover components */
.bionic-nav-popover {
  top: -22px !important;
  left: -10px !important;

  &.top-0 {
    top: 0 !important;
  }

  .arrow {
    display: none !important;
  }

  .popover-body {
    max-width: 324px;
  }

  .bionic-popover-heading {
    margin-bottom: 1.2rem;
  }

  .bionic-nav-menu-container {
    min-width: 300px;
    padding: 16px 8px 8px 8px;
  }

  .bionic-nav-menu-heading {
    font-size: 0.875rem;
  }

  .btn-bionic-nav-menu-item {
    width: 100%;
    text-align: left;
    padding: 0.375rem 0.75rem;
    margin: 1px;
    &:hover {
      background-color: #EEF4FF;
      //color: #444CE7;
    }

    //&.active-link,
    //&.exact-active-link,
    &.selected {
      font-weight: 600;
      background-color: #EEF4FF;
      color: #444CE7;
    }
  }
}
</style>
