<template>
  <div></div>
</template>

<script>
import _ from 'lodash'

// TODO turn this into a composable
export default {
  name: 'ScrollTracker',
  data () {
    return {
      hasScrolledPastHeader: false,
      headerHeightVal: 47
    }
  },
  methods: {
    setScrolledPastHeader: function (val) {
      if (val !== this.hasScrolledPastHeader) {
        this.$store.dispatch('setScrolledPastHeader', val)
        this.hasScrolledPastHeader = val
      }
    },
    handleScroll: _.throttle(
      function () {
        this.setScrolledPastHeader(window.scrollY > this.headerHeight)
      }, 333
    )
  },
  created () {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
    this.$store.commit('setScrolledPastHeader', false)
    this.$store.commit('setHeaderHeight', this.headerHeightVal)
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  },
  computed: {
    headerHeight () {
      return this.$store.getters.getHeaderHeight
    }
  }
  // watch: {
  //   headerHeightVal: {
  //     immediate: true,
  //     handler (val) {
  //       this.$store.commit('setHeaderHeight', val)
  //     }
  //   }
  // }
}
</script>

<style scoped>
</style>
