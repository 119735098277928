<template>
  <div>
    <span class="d-none" v-shortkey="['meta', 'shift', 'j']" @shortkey="handleShortKey()"></span>
    <b-modal v-model="modalOpen"
             id="jumper-modal"
             size="med"
             body-class="overflow-visible"
             hide-header
             hide-footer
             @hidden="userInput = ''"
             @shown="focusInput()">
      <b-form-input v-model="userInput"
                    placeholder="Jump To..."
                    :debounce="200"
                    :tabindex="600"
                    size="lg"
                    class="jumper-modal-input"
                    :class="{'displaying-content': hasContent}"
                    @keyup.enter="goToFirstLink()"
                    @keyup.down="adjustFocus(1)"></b-form-input>
      <div v-if="hasContent" class="jumper-content bg-white p-4">

<!--        Single Account-->

        <template v-if="content.accounts.length === 1">
          <div v-for="account in content.accounts" :key="account.accountPlanId" class="pb-3 px-4">
            <h5>{{account.name}}</h5>
            <div class="d-flex flex-wrap">
              <a v-for="(ql, qli) in quickLinks"
                 :key="ql.name"
                 class="d-flex-center mt-3 w-50 jump-option"
                 :tabindex="601 + qli"
                 @keyup.down="adjustFocus(1)"
                 @keyup.up="adjustFocus(-1)"
                 @keyup.enter="handleClick(ql.link(account.accountPlanId), $event)"
                 @click="handleClick(ql.link(account.accountPlanId), $event)">
                  <fluency-icon :type="ql.icon" class="round mr-3 d-flex p-3" :class="'bg-' + ql.color"></fluency-icon>
                <div>
                  <div>{{ql.name}}</div>
                  <small class="text-muted">{{ql.link(account.accountPlanId)}}</small>
                </div>
              </a>
            </div>
          </div>
        </template>

<!--        Accounts-->

        <div v-if="content.accounts.length > 1" class="pb-3 px-4">
          <a v-for="(account, i) in content.accounts"
             :key="account.accountPlanId"
             class="d-flex-center mt-3 jump-option"
             :tabindex="601 + i"
             @keyup.down="adjustFocus(1)"
             @keyup.up="adjustFocus(-1)"
             @keyup.enter.stop="handleClick(`/manage/account/?id=${account.accountPlanId}`, $event)"
             @click="handleClick(`/manage/account/?id=${account.accountPlanId}`, $event)">
             <fluency-icon type="account" class="round mr-3 d-flex p-3 bg-darkblue"></fluency-icon>
            <div>
              <div>{{account.name}}</div>
              <small class="text-muted">/manage/account/?id={{account.accountPlanId}}</small>
            </div>
          </a>
          <div v-if="content.totAccount > 10" class="border-top pt-3 mt-3 px-4">
            {{content.totAccount - 10}} More. Refine Your Search To See More
          </div>
        </div>

<!--        Nav Links-->

        <div v-if="content.nav.length > 0" class="pb-3 px-4" :class="{'border-top pt-4': content.totAccount > 0}">
          <a v-for="(link, i) in content.nav"
             :key="link.text"
             class="d-flex-center mt-3 jump-option"
             :tabindex="601 + i + (content.accounts.length === 1 ? 4 : content.accounts.length)"
             @keyup.down="adjustFocus(1)"
             @keyup.up="adjustFocus(-1)"
             @click="handleClick(link.link, $event)"
             @keyup.enter="handleClick(link.link, $event)">
            <fluency-icon type="link" class="round mr-3 d-flex p-3"></fluency-icon>
            <div>
              <div>{{link.text}}</div>
              <small class="text-muted">{{link.link}}</small>
            </div>
          </a>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import { useAccountsMinimal } from '@/composables/useAccountsMinimal'

export default {
  name: 'jumper2',
  setup () {
    const { accountsMinimal } = useAccountsMinimal()

    return {
      accountsMinimal
    }
  },
  data () {
    return {
      modalOpen: false,
      userInput: ''
    }
  },
  computed: {
    navigation () {
      return this.$store.getters.navigationForUser
    },
    quickLinks () {
      const quickLinks = [
        {
          name: 'Manage',
          icon: 'account',
          color: 'darkblue',
          link: (id) => `/manage/account/?id=${id}`
        },
        {
          name: 'Notifications',
          icon: 'bell',
          color: 'red',
          link: (id) => `/alerts/?view=account&accounts=${id}`
        },
        {
          name: 'Data Explorer',
          icon: 'analyze',
          color: 'lightgreen',
          link: (id) => `/reporting/data-explorer/?account=${id}`
        }
      ]
      if (this.$store.getters.user?.internalUser || this.$store.getters.user.capabilities?.PeerAnalysis) {
        quickLinks.push(
          {
            name: 'Peer Insights',
            icon: 'person',
            color: 'purple',
            link: (id) => `/insights/peer-insights/?account=${id}`
          }
        )
      } else {
        quickLinks.push({
          name: 'Dashboard',
          icon: 'dashboard',
          color: 'purple',
          link: (id) => `/dashboard/?accountPlanId=${id}`
        })
      }
      return quickLinks
    },
    flatNav () {
      const nav = [...this.navigation.navigation]
      for (const d of [this.navigation.settings, this.navigation.reporting]) {
        for (const p of d) {
          for (const s of p.sections) {
            for (const l of s) {
              nav.push(l)
            }
          }
        }
      }
      return nav
    },
    content () {
      if (this.userInput.length > 2) {
        const accounts = this.accountsMinimal().filter(a => a.name.toLowerCase().includes(this.userInput.toLowerCase())) ?? []
        return {
          accounts: accounts.slice(0, 10),
          totAccount: accounts.length,
          nav: this.flatNav.filter(l => l.text.toLowerCase().includes(this.userInput.toLowerCase()))
        }
      }
      return {
        accounts: [],
        nav: []
      }
    },
    hasContent () {
      return this.content.accounts.length > 0 || this.content.nav.length > 0
    }
  },
  methods: {
    handleShortKey () {
      this.modalOpen = true
    },
    closeModal () {
      this.modalOpen = false
    },
    focusInput () {
      this.$nextTick(() => {
        const elem = document.querySelector('.jumper-modal-input')
        if (elem) {
          elem.focus()
        }
      })
    },
    adjustFocus (modifier) {
      const current = document.activeElement?.getAttribute('tabindex')
      if (current) {
        const newEl = document.querySelector(`[tabindex="${parseInt(current) + modifier}"]`)
        if (newEl) {
          newEl.focus()
        }
      }
    },
    openModal () {
      this.modalOpen = true
    },

    handleClick (link, event) {
      this.$track.event(
        'Jumper Navigate',
        {
          path: link
        },
        'Navigation',
        event?.target
      )
      this.$router.push(link)
      this.closeModal()
    },
    async goToFirstLink () {
      if (this.content.accounts?.length > 0) {
        this.handleClick(`/manage/account/?id=${this.content.accounts[0].accountPlanId}`)
      } else if (this.content.nav?.length > 0) {
        this.handleClick(this.content.nav[0].link)
      } else if (this.$store.getters.user?.internalUser && !isNaN(parseInt(this.userInput))) {
        const customer = await this.$res.fetch.getCustomerFromUrl(`/manage/account/?id=${this.userInput}`)
        if (customer) {
          const actingAs = this.$store.getters.actingAsUser
          if (actingAs && customer.customerId === actingAs) {
            this.handleClick(`/manage/account/?id=${this.userInput}`)
          } else if (!actingAs && this.$store.user?.customerId === customer.customerId) {
            this.handleClick(`/manage/account/?id=${this.userInput}`)
          } else {
            const env = this.$route.query.fsEnv
            window.location = `${window.location.origin}/manage/account/?id=${this.userInput}${env ? '&fsEnv=' + env : ''}`
          }
        }
      }
    }
  },
  mounted () {
    this.$eventBus.$on('openAccountJump', this.openModal)
  },
  beforeUnmount () {
    this.$eventBus.$off('openAccountJump', this.openModal)
  }
}
</script>
<style lang="scss">
#jumper-modal {
  .modal-content {
    background: transparent;
    border: none;
  }
  .jumper-modal-input.displaying-content {
    border-radius: 0.3rem 0.3rem 0 0;
  }
  .jumper-content {
    border-radius: 0 0 0.3rem 0.3rem;
    border: 1px solid $border-color;
    border-top: none;
  }
  .bg-lightgreen {
    background: $fluency-lightgreen;
  }
  .bg-darkblue {
    background: $fluency-darkblue;
  }
  .bg-purple {
    background: $fluency-purple;
  }
  .bg-red {
    background: $fluency-red;
  }
  .jump-option {
    color: $secondary;
    cursor: pointer;
  }
  .jump-option:hover,
  .jump-option:focus {
    color: $fluency-blue;
    outline: 0;
    .round {
      background: $fluency-blue !important;
    }
  }
}
</style>
