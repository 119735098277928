<template>
  <div></div>
</template>

<script>
// <script language='javascript' async='true' type='text/javascript' src='https://cdn.whatfix.com/prod/db770019-c348-42d4-bad2-d94360af6016/initiator/initiator.nocache.js'>

export default {
  name: 'WhatFix',
  data () {
    return {
      userDefined: false,
      roleOrder: ['user', 'user-manager', 'manager', 'admin']
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  watch: {
    user: {
      immediate: true,
      handler (user) {
        if (user && !this.userDefined && window) {
          this.defineUser(user)
          this.addScript()
        }
      }
    }
  },
  methods: {
    defineUser (user) {
      window.whatFixUser = {
        userId: user.userRegistryId,
        customerId: user.customerId,
        role: this.highestRole(user.recentRoles?.split(',') || []),
        newUser: this.isNewUser(user.createdOn),
        internalUser: user.internalUser ? 'yes' : 'no'
      }
      this.userDefined = true
    },
    addScript () {
      const whatFixScript = document.createElement('script')
      whatFixScript.setAttribute('src', 'https://cdn.whatfix.com/prod/db770019-c348-42d4-bad2-d94360af6016/initiator/initiator.nocache.js')
      whatFixScript.setAttribute('type', 'text/javascript')
      whatFixScript.setAttribute('async', 'true')
      document.head.appendChild(whatFixScript)
    },
    highestRole (roles) {
      const index = roles.reduce((accumulator, current) => {
        const currentIndex = this.roleOrder.indexOf(current.toLowerCase())
        if (currentIndex > accumulator) return currentIndex
        return accumulator
      }, 0)
      return this.roleOrder[index]
    },
    isNewUser (createdOnTimestamp) {
      if (!createdOnTimestamp) return false
      const createdOn = new Date(createdOnTimestamp)
      const now = new Date()
      return (now.getTime() / 86400000) - (createdOn.getTime() / 86400000) < 90
    }
  }
}
</script>
