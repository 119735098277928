import { ValidChannels } from './validChannels'
const keywordChannels = [1, 3, 4, 10, 24]
export const settingsNav = {
  account: {
    settings: {
      label: 'Account Settings & Tools',
      sections: [
        {
          label: 'Budgeting',
          links: [
            {
              label: 'Budget Settings',
              defaultPriority: 2,
              query: {
                form: 'budgeting',
                content: 'settings'
              }
            },
            {
              label: 'Groups',
              mustHaveCapabilities: ['CampaignGroupBudgets'],
              defaultPriority: 1,
              query: {
                form: 'budgeting',
                content: 'groups'
              }
            }
          ]
        },
        {
          label: 'Settings',
          links: [
            {
              label: 'Conversion Actions',
              validChannels: [29, 30],
              query: {
                form: 'conversionActions'
              }
            },
            {
              label: 'Broadcast',
              validChannels: [1, 2],
              query: {
                form: 'broadcast'
              }
            },
            {
              label: 'Account Performance',
              query: {
                form: 'accountPerformance'
              }
            },
            {
              label: 'Keyword Recommendations',
              validChannels: keywordChannels,
              query: {
                form: 'keywordRecommendations'
              }
            },
            {
              label: 'Blocked Keywords',
              validChannels: keywordChannels,
              query: {
                form: 'blockedKeywords'
              }
            },
            {
              label: 'Phone Routing',
              mustHaveUserCapabilities: ['PhoneRouting'],
              query: {
                form: 'phoneRouting'
              }
            },
            {
              label: 'Display Ads',
              mustHaveCapabilities: ['DisplayAds'],
              query: {
                form: 'displayads'
              }
            }
          ]
        },
        {
          label: 'Partners',
          links: [
            {
              label: 'Partner Accounts',
              query: {
                form: 'partners',
                content: 'partnerAccounts'
              }
            },
            {
              label: 'Tracking Tags',
              query: {
                form: 'partners',
                content: 'trackingTags'
              }
            },
            {
              label: 'Name Overrides',
              query: {
                form: 'partners',
                content: 'nameOverrides'
              }
            }
          ]
        },
        {
          label: 'Basic',
          links: [
            {
              label: 'Account Details',
              query: {
                form: 'basic',
                content: 'general'
              }
            }
          ]
        }
      ]
    },
    blueprints: {
      label: 'Blueprints & Data Sources',
      sections: [
        {
          label: 'Data Sources',
          links: [
            {
              label: 'Data Sources',
              query: {
                form: 'blueprints',
                content: 'pollingSources'
              }
            },
            {
              label: 'Media Sources',
              query: {
                form: 'blueprints',
                content: 'mediaSources'
              }
            },
            {
              label: 'Account Data',
              defaultPriority: 1,
              query: {
                form: 'blueprints',
                content: 'customData'
              }
            },
            {
              label: 'Form Data',
              query: {
                form: 'blueprints',
                content: 'formData'
              }
            },
            {
              label: 'Promotion Scheduler',
              query: {
                form: 'blueprints',
                content: 'promotionScheduler'
              }
            }
          ]
        },
        {
          label: 'Blueprint Tools',
          links: [
            {
              label: 'Feature Flags',
              query: {
                form: 'blueprints',
                content: 'featureFlags'
              }
            },
            {
              label: 'Blueprint Tweaks',
              query: {
                form: 'blueprints',
                content: 'blueprintTweaks'
              }
            },
            {
              label: 'Subscriptions',
              query: {
                form: 'blueprints',
                content: 'subscriptions'
              }
            },
            {
              label: 'Catalogs',
              query: {
                form: 'productcatalogs'
              }
            },
            {
              label: 'Competitors',
              query: {
                form: 'basic',
                content: 'competitors'
              }
            }
          ]
        }
      ]
    },
    targeting: {
      label: 'Account Targeting',
      sections: [
        {
          label: 'Cross-Channel',
          links: [
            {
              label: 'Locations',
              defaultPriority: 1,
              query: {
                form: 'locations'
              }
            }
          ]
        },
        {
          label: 'Search',
          validChannels: [1],
          links: [
            {
              label: 'Google Audiences',
              query: {
                form: 'userlists',
                content: 'eligibleForSearch'
              }
            },
            {
              label: 'Negative Keyword Sets',
              validChannels: keywordChannels,
              query: {
                form: 'sharedsets'
              }
            }
          ]
        },
        {
          label: 'Display',
          validChannels: [3],
          links: [
            {
              label: 'Display Audiences',
              query: {
                form: 'userlists',
                content: 'eligibleForDisplay'
              }
            }
          ]
        },
        {
          label: 'DSP',
          validChannels: [29, 30],
          links: [
            {
              label: 'Domain Lists',
              query: {
                form: 'blueprints',
                content: 'domainLists'
              }
            },
            {
              label: 'Contextual Targets',
              query: {
                form: 'targetSets',
                content: 'CONTEXTUAL_TARGET'
              }
            },
            {
              label: '3rd Party Audiences',
              query: {
                form: 'targetSets',
                content: 'AUDIENCE'
              }
            },
            {
              label: 'DSP Custom Audiences',
              query: {
                form: 'userlists',
                content: 'eligibleForDsp'
              }
            }
          ]
        },
        {
          label: 'Social',
          validChannels: [2],
          links: [
            {
              label: 'Meta User Lists',
              query: {
                form: 'userlists',
                content: 'eligibleForFacebook'
              }
            },
            {
              label: 'Facebook Page Posts',
              query: {
                form: 'pagePosts'
              }
            }
          ]
        }
      ]
    }
  },
  campaign: {
    settings: {
      label: 'Campaign Settings & Tools',
      sections: [
        {
          label: 'Tools',
          links: [
            {
              label: 'Analyze Campaign',
              query: {
                form: 'analyzeCampaign'
              }
            },
            {
              label: 'Settings Replicator',
              query: {
                form: 'settingsReplicator'
              }
            },
            {
              label: 'Partner Check',
              query: {
                form: 'partnerCheck'
              }
            }
          ]
        },
        {
          label: 'General',
          links: [
            {
              label: 'Campaign Details',
              defaultPriority: 1,
              validChannels: ValidChannels.CampaignTabs.basic,
              query: {
                form: 'basic'
              }
            },
            {
              label: 'Bidding',
              validChannels: ValidChannels.CampaignTabs.bidding,
              query: {
                form: 'bidding'
              }
            },
            {
              label: 'Attributes',
              validChannels: ValidChannels.CampaignTabs.attributes,
              query: {
                form: 'attributes'
              }
            },
            {
              label: 'Phone Routing',
              mustHaveUserCapabilities: ['PhoneRouting'],
              validChannels: ValidChannels.CampaignTabs.phoneRouting,
              query: {
                form: 'phoneRouting'
              }
            },
            {
              label: 'Insertion Orders/Line Items',
              validChannels: ValidChannels.CampaignTabs.dv360,
              query: {
                form: 'dv360'
              }
            },
            {
              label: 'Inventory',
              validChannels: ValidChannels.CampaignTabs.inventory,
              query: {
                form: 'inventory'
              }
            },
            {
              label: 'Conversions',
              validChannels: ValidChannels.CampaignTabs.conversions,
              query: {
                form: 'conversions'
              }
            },
            {
              label: 'RTB Configurations',
              validChannels: ValidChannels.CampaignTabs['rtb-configurations'],
              query: {
                form: 'rtb-configurations'
              }
            },
            {
              label: 'Trackers',
              validChannels: ValidChannels.CampaignTabs.trackers,
              query: {
                form: 'trackers'
              }
            }
          ]
        }
      ]
    },
    targeting: {
      label: 'Campaign Targeting',
      sections: [
        {
          label: 'Targeting',
          links: [
            {
              label: 'Locations',
              validChannels: ValidChannels.CampaignTabs.locations,
              query: {
                form: 'locations'
              }
            },
            {
              label: 'Targets',
              validChannels: ValidChannels.CampaignTabs.targets,
              query: {
                form: 'targets'
              }
            },
            {
              label: 'Contextual Targets',
              validChannels: ValidChannels.CampaignTabs.targetingAndAudiences,
              query: {
                form: 'targetingAndAudiences',
                content: 'CONTEXTUAL_TARGET'
              }
            },
            {
              label: '3rd Party Audiences',
              validChannels: ValidChannels.CampaignTabs.targetingAndAudiences,
              query: {
                form: 'targetingAndAudiences',
                content: 'AUDIENCE'
              }
            },
            {
              label: 'Custom Audiences',
              validChannels: [29, 30],
              query: {
                form: 'userlists'
              }
            },
            {
              label: 'Domain Lists',
              validChannels: ValidChannels.CampaignTabs.domainLists,
              query: {
                form: 'domain-lists'
              }
            },
            {
              label: 'Applied Audiences',
              validChannels: [1, 2, 3],
              mustHaveCapabilities: ['Audiences'],
              query: {
                form: 'userlists'
              }
            },
            {
              label: 'Languages',
              validChannels: ValidChannels.CampaignTabs.languages,
              query: {
                form: 'languages'
              }
            },
            {
              label: 'Network',
              validChannels: ValidChannels.CampaignTabs.network,
              query: {
                form: 'network'
              }
            },
            {
              label: 'Start/End Dates',
              validChannels: ValidChannels.CampaignTabs['start-and-end-dates'],
              query: {
                form: 'start-and-end-dates'
              }
            },
            {
              label: 'Ad Scheduling',
              validChannels: ValidChannels.CampaignTabs['ad-scheduling'],
              query: {
                form: 'ad-scheduling'
              }
            },
            {
              label: 'Services',
              validChannels: ValidChannels.CampaignTabs.services,
              query: {
                form: 'services'
              }
            },
            {
              label: 'Video Config',
              validChannels: ValidChannels.CampaignTabs.videoConfig,
              query: {
                form: 'videoConfig'
              }
            }
          ]
        },
        {
          label: 'Keyword Management',
          validChannels: keywordChannels,
          links: [
            {
              label: 'Negative Keywords',
              query: {
                form: 'negative-keywords'
              }
            },
            {
              label: 'Blocked Keywords',
              validChannels: [1, 3, 4, 10, 24],
              query: {
                form: 'blockedKeywords'
              }
            },
            {
              label: 'Keyword Recommendations',
              validChannels: [1, 3, 4, 10, 24],
              query: {
                form: 'keywordRecommendations'
              }
            }
          ]
        }
      ]
    }
  },
  adGroup: {
    settings: {
      label: 'Ad Group Settings & Targeting',
      sections: [
        {
          label: 'General',
          links: [
            {
              label: 'Ad Group Details',
              validChannels: ValidChannels.AdGroupTabs.basic,
              defaultPriority: 1,
              query: {
                form: 'basic'
              }
            },
            {
              label: 'Attributes',
              validChannels: ValidChannels.AdGroupTabs.attributes,
              query: {
                form: 'attributes'
              }
            },
            {
              label: 'Partner Check',
              query: {
                form: 'partnerCheck'
              }
            }
          ]
        },
        {
          label: 'Targeting',
          links: [
            {
              label: 'Negative Keywords',
              validChannels: ValidChannels.AdGroupTabs.negativeKeywords,
              query: {
                form: 'negativeKeywords'
              }
            },
            {
              label: 'Start/End Dates',
              validChannels: ValidChannels.AdGroupTabs.startAndEndDates,
              query: {
                form: 'startAndEndDates'
              }
            },
            {
              label: 'Placements',
              validChannels: ValidChannels.AdGroupTabs.placement,
              query: {
                form: 'placement'
              }
            },
            {
              label: 'Audiences',
              validChannels: ValidChannels.AdGroupTabs.userlists,
              query: {
                form: 'userlists'
              }
            },
            {
              label: 'Audience',
              validChannels: ValidChannels.AdGroupTabs.audience,
              query: {
                form: 'audience'
              }
            },
            {
              label: 'Targets',
              validChannels: ValidChannels.AdGroupTabs.targets,
              query: {
                form: 'targets'
              }
            },
            {
              label: 'Locations',
              validChannels: ValidChannels.AdGroupTabs.locations,
              query: {
                form: 'locations'
              }
            },
            {
              label: 'Product Groups',
              validChannels: ValidChannels.AdGroupTabs.productGroups,
              query: {
                form: 'productGroups'
              }
            }
          ]
        }
      ]
    }
  }
}
