<template>
  <drawer :show="drawerOpen"
          :right="true"
          :drawer-width="`${drawerWidth}%`"
          :no-scrim="true"
          :size-tracker="true"
          :drawer-classes="appForkSettings ? 'bg-bionic-nav border-left' : ''"
          :card-classes="appForkSettings ? 'border-0 mb-0 bg-transparent' : ''"
          card-header-classes="m-0 border-0 px-4"
          cardBodyClasses="p-0 m-0"
          close-btn-classes="settings-drawer-close-button"
          @visible-size="drawerSize($event)"
          @update:drawerWidth="setDrawerWidth($event)"
          @update:show="drawerOpen = $event, handleManageSettingsClose($event)">
    <t-alert v-if="!isManageEligible" show variant="info">Upgrade to Pro to see settings!</t-alert>
    <fluency-loader v-if="loading" dots color></fluency-loader>
    <template #title>
      <ancestry-navigation default-item="settingsItem" />
    </template>
    <template #helpButton>
      <div v-if="whatFixEnabled">
        <fluency-support />
      </div>
    </template>
    <template v-if="drawerOpen && !loading && settingsItem && isManageEligible">
      <div class="rounded border bg-white mx-4 py-4">
        <small class="d-block ml-4">{{settingsItem.name}}</small>
        <metrics-settings-panel default-item="settingsItem" class="metrics-pane overflow-hidden" />
      </div>
      <div class="pr-4" :class="{'pl-4': !appForkSettings, 'd-flex': appForkSettings}" :style="`height: calc(100% - ${headerOffsetDrawer}px); max-height: calc(100% - ${headerOffsetDrawer}px);`">
        <settings-drawer-nav v-if="appForkSettings && ['account', 'campaign', 'adgroup'].includes(settingsItemType)"></settings-drawer-nav>
        <ad-settings v-if="settingsItemType === 'ad'" />
        <ad-group-manage-tabs v-else-if="settingsItemType === 'adgroup'"/>
        <manage-campaign-settings v-else-if="settingsItemType === 'campaign'" />
        <account-view v-else-if="settingsItemType === 'account'" showBudget showBroadcast />
        <manage-account-budget v-else-if="settingsItemType === 'budget'" />
        <manage-extension-settings v-else-if="settingsItemType === 'extension'" />
        <manage-form-settings v-else-if="settingsItemType === 'form'" />
      </div>
    </template>
  </drawer>
</template>

<script>
import Drawer from 'core-ui/components/common/drawer.vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader.vue'
import AncestryNavigation from '@/components/pages/manage/ancestry-navigation.vue'
import MetricsSettingsPanel from '@/components/pages/manage/metricsSettingsPanel.vue'
import { defineAsyncComponent } from 'vue'
import SettingsDrawerNav from '../../common/settingsDrawerNav.vue'
import FluencySupport from '../../common/fluencySupport.vue'

export default {
  name: 'settingsDrawer',
  components: {
    SettingsDrawerNav,
    AncestryNavigation,
    Drawer,
    MetricsSettingsPanel,
    AdSettings: defineAsyncComponent(() => import('@/components/pages/manage/adSettings.vue')), // : () => import('@/components/pages/manage/adSettings.vue'),
    AdGroupManageTabs: defineAsyncComponent(() => import('@/components/pages/manage/adGroup/adGroupManageTabs.vue')), // : () => import('@/components/pages/manage/adGroup/adGroupManageTabs.vue'),
    ManageCampaignSettings: defineAsyncComponent(() => import('@/components/pages/manage/campaign/manageCampaignSettings.vue')), // : () => import('@/components/pages/manage/campaign/manageCampaignSettings.vue'),
    AccountView: defineAsyncComponent(() => import('@/components/common/accountView.vue')), // : () => import('@/components/common/accountView.vue'),
    ManageAccountBudget: defineAsyncComponent(() => import('@/components/pages/manage/manageAccountBudget.vue')), // : () => import('@/components/pages/manage/manageAccountBudget.vue'),
    ManageExtensionSettings: defineAsyncComponent(() => import('@/components/pages/manage/extensions/manageExtensionSettings.vue')), // : () => import('@/components/pages/manage/extensions/manageExtensionSettings.vue'),
    ManageFormSettings: defineAsyncComponent(() => import('@/components/pages/manage/forms/manageFormSettings.vue')), // : () => import('@/components/pages/manage/forms/manageFormSettings.vue'),
    FluencyLoader,
    FluencySupport
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawerOpen: false,
      loading: false
    }
  },
  watch: {
    '$route.query.settingsItemId': {
      immediate: true,
      handler: function (val, old) {
        if (old && !val) {
          this.drawerOpen = false
          this.$store.commit('settingsItem', null)
        } else if (val) {
          if (!this.drawerOpen) {
            this.drawerOpen = true
          }
          this.loadSettingsItem()
        }
      }
    }
  },
  computed: {
    appForkSettings () {
      return this.$store.getters.appForkSettings
    },
    drawerWidth () {
      return this.$store.getters.manageDrawerWidth
    },
    headerOffsetDrawer () {
      return this.$store.getters.getHeaderHeight + 10
    },
    settingsItem () {
      return this.$store.getters.settingsItem
    },
    settingsItemType () {
      if (!this.settingsItem) {
        return ''
      }
      return this.itemType(this.settingsItem)
    },
    isManageEligible () {
      return this.$store.getters.user?.capabilities?.Manage
    },
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    whatFixEnabled () {
      return this.user?.capabilities?.WhatFix
    }
  },
  methods: {
    async loadSettingsItem () {
      if (!this.isManageEligible) return
      this.loading = true
      const { settingsItemId, settingsItemType } = this.$route.query
      if (!settingsItemId || !settingsItemType) {
        this.loading = false
        return
      }
      const activeItem = this.$store.getters.activeItem
      if (activeItem) {
        const activeItemType = this.itemType(activeItem)
        const activeItemId = activeItem[this.primaryKey(activeItemType)]
        if (activeItemType === settingsItemType && activeItemId === parseInt(settingsItemId)) {
          await this.$store.commit('settingsItem', activeItem)
          this.loading = false
          return
        }
      }
      const fetchType = this.convertManageTypeToFetchType(settingsItemType)
      await this.$store.dispatch('fetchActiveItem', { planType: fetchType, id: settingsItemId, settings: true })
      this.loading = false
    },
    primaryKey (type) {
      return this.convertManageTypeToFetchType(type) + 'PlanId'
    },
    convertManageTypeToFetchType (type) {
      if (type === 'adgroup') return 'adGroup'
      if (type === 'ad') return 'creative'
      return type
    },
    setDrawerWidth (e) {
      const newWidth = parseInt(e.replace('px'))
      const newPct = (newWidth / window.innerWidth) * 100

      this.$store.dispatch('manageDrawerWidth', newPct)
    },
    drawerSize (e) {
      this.$store.dispatch('manageDrawerBootstrapSize', e)
    },
    itemType (si) {
      if (si.siteLinkPlanId || si.structuredSnippetPlanId || si.calloutPlanId || si.callExtensionPlanId || si.priceExtensionPlanId || si.imageExtensionPlanId || si.extensionPlanId) {
        return 'extension'
      } else if (si.creativePlanId) {
        return 'ad'
      } else if (si.formPlanId) {
        return 'form'
      } else if (si.adGroupPlanId) {
        return 'adgroup'
      } else if (si.campaignPlanId) {
        return 'campaign'
      } else if (si.budgetPlanId) {
        return 'budget'
      } else if (si.accountPlanId) {
        return 'account'
      }
      return ''
    },
    handleManageSettingsClose (val) {
      if (val === false) { // the event must be a close event, either escape key or click on the close
        const { settingsItemId, settingsItemType, form, content, ...otherParams } = this.$route.query
        this.$router.push({ query: otherParams })
      }
    }
  }
}
</script>
