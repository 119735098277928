<template>
  <b-modal v-model="modalOpen"
           size="sm" title="Manage MFA Login for Backpack"
           body-class="mfa-enroll-modal"
           :hide-header-close="forceMFA"
           :no-close-on-backdrop="forceMFA"
           :no-close-on-esc="forceMFA"
           @hidden="handleHidden()">

    <fluency-loader v-if="loading" dots></fluency-loader>
    <template v-else-if="enrollFlow">
      <div v-if="verifyFactorData" class="px-5">
        <label>1. Scan</label>
        <p class="text-black-50">Scan the QR code with your authenticator app.</p>
        <div class="d-flex justify-content-center">
          <img v-if="verifyFactorData.okta_factor_qrcode_link" id="qrCodeImg" class="img-fluid" :src="verifyFactorData.okta_factor_qrcode_link"/>
        </div>
        <label>2. Verify</label>
        <p class="text-black-50">Enter the pass code from your authenticator app to complete enrollment.</p>
        <verify-factor alignment="center" @input="activateFactor($event)" :error-message="errorMessage" :loading="loading"></verify-factor>
      </div>
      <div v-if="!enrollFactorType">
        <label>Choose a Factor Type</label>
        <div v-for="option in factorTypeOptions" :key="option.factorType" class="my-3 p-3 rounded border glow-hover cursor-pointer" @click="handleFactorTypeSelect(option.factorType)">
          <h5>{{option.label}}</h5>
          <span class="text-muted">{{option.description}}</span>
        </div>
      </div>
      <div v-else-if="enrollFactorType === 'sms'">
        <b-form-group label="Enter a Phone Number" description="This is where you'll receive the security code.">
          <b-form-input size="lg" autofocus v-model="phoneNumber" :formatter="formatPhoneNumber"></b-form-input>
        </b-form-group>
      </div>
    </template>

    <template v-else>
      <div v-if="userEnrolled">
        <label>Enrolled Factors:</label>
        <div v-for="(factor, factorId) in userFactors" :key="factorId" class="my-3 p-3 rounded border d-flex" :class="{'border-warning': (forceMFA || mfaWarn) && factor.okta_factor_status !== 'ACTIVE'}">
          <div>
            <h5>{{labelForFactorType(factor.okta_factor_type)}}</h5>
            <b-badge :variant="factor.okta_factor_status === 'ACTIVE'  ? 'success' : 'secondary'">{{$filters.convertFromCapsUnderscore(factor.okta_factor_status)}}</b-badge>
            <t-button-inline v-if="factor.okta_factor_status === 'PENDING_ACTIVATION'" @click="activatePendingFactor(factor)">Activate</t-button-inline>
          </div>
          <t-button-inline severity="danger" class="ml-auto" @click="confirmAndRemoveFactor(factor)">
            <fluency-icon type="delete"></fluency-icon>
            Delete
          </t-button-inline>
        </div>
        <div v-if="mfaWarn" class="d-flex align-items-end mt-4">
          <h3 class="m-0 font-weight-bold">{{daysUntilForceMFA}}</h3>
          <span class="ml-3">day{{daysUntilForceMFA > 1 ? 's' : ''}} left to activate.</span>
        </div>
        <t-alert v-if="forceMFA" severity="warn" class="mt-4">Your organization requires that you activate MFA to continue.</t-alert>
      </div>
      <div v-else class="py-5">
        <template v-if="mfaWarn || forceMFA">
          <h4 class="text-info mb-4">You're Required to Enroll in MFA</h4>
          <p class="text-black-50">Your organization is requiring that you enroll in MFA for extra security.</p>
          <div v-if="mfaWarn" class="pt-5 mt-5 d-flex align-items-end">
            <h3 class="m-0 font-weight-bold">{{daysUntilForceMFA}}</h3>
            <span class="ml-3">day{{daysUntilForceMFA > 1 ? 's' : ''}} left to enroll.</span>
          </div>
        </template>
        <template v-else>
          <h4 class="text-info mb-4">Looks Like You're Not Enrolled</h4>
          <p class="text-black-50">Enrolling in MFA is easy and provides extra security for you and your organization.</p>
        </template>
      </div>
    </template>

    <template #modal-footer="{ cancel }">

        <b-button v-if="enrollFactorType === 'sms'" variant="link" class="mr-auto" @click="enrollFactorType = ''">Back</b-button>
        <b-button :disabled="forceMFA" @click="cancel()">Done</b-button>
        <b-button v-if="enrollFactorType === 'sms'"
                  variant="primary"
                  class="ml-3"
                  :disabled="strippedPhoneNumber.length !== 10"
                  id="mfaEnrollButton"
                  @click="enrollFactor()">Enroll</b-button>
       <b-button v-if="!userEnrolled && !enrollFlow" variant="outline-info" @click="initEnrollFlow()">Enroll</b-button>
    </template>
  </b-modal>
</template>

<script>
import VerifyFactor from '@/components/common/forms/verifyFactor.vue'
import FluencyLoader from 'core-ui/components/common/fluencyLoader.vue'

export default {
  name: 'mfaEnroll',
  components: { VerifyFactor, FluencyLoader },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      enrollFlow: false,
      enrollFactorType: 'token:software:totp', // hard coded until we support more. The work for sms is mostly done
      userFactors: undefined,
      loading: false,
      phoneNumber: '',
      errorMessage: '',
      verifyFactorData: undefined,
      customer: undefined,
      alreadyWarnedToday: false,
      factorTypeOptions: [
        {
          label: 'Authenticator App',
          factorType: 'token:software:totp',
          description: 'Use an app, like Authy or Google Authenticator, to receive a security code.'
        },
        {
          label: 'Text Message',
          factorType: 'sms',
          description: 'Receive a security code via text message.'
        }
      ],
      factorTypes: [
        {
          factorType: 'token:software:totp',
          provider: 'GOOGLE'
        },
        {
          factorType: 'sms',
          provider: 'OKTA',
          profile: {
            phoneNumber: '+1-555-415-1337'
          }
        }
      ]
    }
  },
  computed: {
    modalOpen: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    forceEnrollDate () {
      return this.customer?.mfaRequiredDate
    },
    strippedPhoneNumber () {
      return this.phoneNumber.replace(/[^0-9]+/g, '')
    },
    oktaUserId () {
      return this.$store.getters.oktaUserId
    },
    internalUser () {
      return this.$store.getters.user?.internalUser
    },
    userEnrolled () {
      return this.userFactors && Object.keys(this.userFactors).length > 0
    },
    userEnrolledActive () {
      return this.userFactors && Object.values(this.userFactors).filter(factor => factor.okta_factor_status === 'ACTIVE').length > 0
    },
    daysUntilForceMFA () {
      if (this.forceEnrollDate) {
        const forceEnrollDate = new Date(this.forceEnrollDate)
        const today = new Date()
        const millisBetween = forceEnrollDate.getTime() - today.getTime()
        return Math.ceil(millisBetween / (1000 * 60 * 60 * 24))
      }
      return 1000
    },
    mfaWarn () {
      return this.daysUntilForceMFA <= 30 &&
        this.daysUntilForceMFA > 0 &&
        this.userFactors &&
        !this.userEnrolledActive &&
        !this.alreadyWarnedToday &&
        !this.internalUser
    },
    forceMFA () {
      return this.daysUntilForceMFA <= 0 &&
        this.userFactors &&
        !this.userEnrolledActive &&
        !this.alreadyWarnedToday &&
        !this.internalUser
    },
    tomorrow () {
      return this.$moment().add(1, 'day').startOf('day').toDate()
    }
  },
  watch: {
    mfaWarn: {
      immediate: true,
      handler (value) {
        if (value) {
          this.modalOpen = true
        }
      }
    },
    forceMFA: {
      immediate: true,
      handler (value) {
        if (value) {
          this.modalOpen = true
        }
      }
    }
  },
  methods: {
    handleFactorTypeSelect (factorType) {
      this.enrollFactorType = factorType
      if (factorType === 'token:software:totp') {
        this.enrollFactor()
      }
    },
    async enrollFactor () {
      // this will need work when we offer more factor types
      this.loading = true
      const resp = await this.$res.authV2.enrollFactor(this.oktaUserId)
      if (resp) {
        this.verifyFactorData = resp
      }
      this.loading = false
    },
    formatPhoneNumber (phoneNumberString) {
      const cleaned = phoneNumberString.replace(/[^0-9]+/g, '')
      if (cleaned.length >= 6) {
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)} ${cleaned.substring(6)}`
      } else if (cleaned.length >= 3) {
        return `(${cleaned.substring(0, 3)}) ${cleaned.substring(3)}`
      }
      return cleaned
    },
    async getFactors () {
      if (this.oktaUserId) {
        this.loading = true
        const resp = await this.$res.authV2.listFactors(this.oktaUserId)
        if (resp) {
          this.userFactors = resp
        }
        this.loading = false
      }
    },
    async getCustomer () {
      if (this.oktaUserId) {
        this.customer = await this.$res.customer.get()
      }
    },
    labelForFactorType (factorType) {
      switch (factorType) {
        case 'TOKEN_SOFTWARE_TOTP':
          return 'Authenticator App'
        default:
          return factorType
      }
    },
    apiTypeForFactorType (factorType) {
      switch (factorType) {
        case 'TOKEN_SOFTWARE_TOTP':
          return 'token:software:totp'
        default:
          return factorType
      }
    },
    async removeFactor (factor) {
      const resp = await this.$res.authV2.deleteFactor({ oktaFactorId: factor.okta_factor_id, oktaUserId: this.oktaUserId })
      if (resp) {
        const userFactors = { ...this.userFactors }
        delete userFactors[factor.okta_factor_id]
        this.userFactors = userFactors
      }
    },
    async confirmAndRemoveFactor (factor) {
      const cfm = await this.$confirm('This will move you back to standard, one-factor login, which is less secure.')
      if (!cfm) return
      this.loading = true
      await this.removeFactor(factor)
      this.loading = false
    },
    async activatePendingFactor (factor) {
      // this is a little strange, but Okta's docs say to delete the pending factor and start over
      this.loading = true
      await this.removeFactor(factor)
      this.enrollFactorType = this.apiTypeForFactorType(factor.okta_factor_type)
      this.initEnrollFlow()
      this.loading = false
    },
    handleHidden () {
      if (this.enrollFlow) {
        this.getFactors()
      }
      this.enrollFlow = false
      if (this.mfaWarn || this.forceMFA) {
        document.cookie = `mfaWarn=true; expires=${this.tomorrow}; path=/`
      }
    },
    initEnrollFlow () {
      this.enrollFlow = true
      if (this.enrollFactorType) {
        this.handleFactorTypeSelect(this.enrollFactorType)
      }
    },
    async activateFactor (passCode) {
      this.loading = true
      const resp = await this.$res.authV2.activateFactor({ passCode, oktaUserId: this.oktaUserId, oktaFactorId: this.verifyFactorData.okta_factor_id }, { headers: { 'Content-Type': 'text/plain' } })
      if (resp) {
        this.errorMessage = ''
        this.$toast('Factor Activated', 'success')
        this.enrollFlow = false
        await this.getFactors()
      } else {
        this.errorMessage = 'Invalid Passcode'
      }
      this.loading = false
    }
  },
  async mounted () {
    const oktaUser = await this.$authn.getOktaUserFromSession()
    if (oktaUser) {
      this.$store.commit('updateOkta', { userId: oktaUser.id })
    }
    this.alreadyWarnedToday = document.cookie.includes('mfaWarn=true')
    this.getFactors()
    this.getCustomer()
  }
}
</script>

<style lang="scss">
.mfa-enroll-modal {
  min-height: 275px;
}
</style>
