<template>
  <b-modal :visible="value"
           :title="verbiage.toolname"
           no-close-on-backdrop
           size="md"
           @show="showModal()"
           @hide="hideModal()">

    <template #modal-footer>
      <div class="d-flex-center w-100">
        <div class="flex-grow-1">
          <b-btn variant="ghost-info"
                 :disabled="wizard.init === false"
                 @click="toggleWizard()">
            {{(wizard.active) ? `Switch to ${verbiage.nonWizard}` : `Switch to ${verbiage.wizard}`}}
          </b-btn>
        </div>
        <b-btn variant="ghost-secondary"
               class="mx-3"
               @click="hideModal()">Cancel</b-btn>
        <b-btn :variant="pendingChanges ? 'warning' : 'success'"
               :disabled="pendingChanges === false"
               @click="okModal()">Save</b-btn>
      </div>
    </template>

    <!-- WIZARD INTRO SCREEN -->
    <div v-if="wizard.init === false" class="d-flex-center-center">
      <div class="wizard-intro d-flex-center-center h-50">
        <div class="">
          <h3 class="text-info mb-4">Set Your {{verbiage.toolname}}</h3>
          <p class="text-black-50 mb-2 white-space-pre-line">We wanted to give you a chance to customize your view of Backpack. You'll be able to pick and choose which tools/features you want to see based on what's most relevant to you.</p>
          <p class="text-black-50 mb-2 white-space-pre-line mb-5">How would you like to do this?</p>

          <t-row class="mt-5">
            <t-col cols="6">
              <p class="text-center">
                <b-btn variant="success"
                       @click="initWizard(true)">{{verbiage.wizard}}</b-btn>
              </p>
              <p class="text-black-50 mb-2 white-space-pre-line">In the {{verbiage.wizard}} we'll give you more detailed explanations of what each section does and who it's for. Probably best if this is your first time using these customizations or you're still new to Backpack.</p>
            </t-col>
            <t-col cols="6">
              <p class="text-center">
                <b-btn variant="success"
                       @click="initWizard(false)">{{verbiage.nonWizard}}</b-btn>
              </p>
              <p class="text-black-50 mb-2 white-space-pre-line">The {{verbiage.nonWizard}} screen shows all options at once with a brief description. Probably what you'll want to use on return visits to these settings.</p>
            </t-col>
          </t-row>
        </div>
      </div>
    </div>

    <!-- WIZARD VIEW -->
    <div v-if="wizard.init && wizard.active" class="d-flex-center-center">
      <div class="wizard-tour">
        <div class="d-flex">
          <div class="wizard-bck px-4 py-3 mr-1 cursor-pointer user-select-none text-info"
               :class="{ 'disabled': wizard.step === 0 }"
               @click="wizardGoBack()">
            <fluency-icon type="chevronLeft" class="mr-2" />
            Previous Section
          </div>
          <div class="wizard-fwd flex-grow-1 text-right px-4 py-3 cursor-pointer user-select-none text-success"
               :class="{ 'disabled': wizard.step === personaObjectLength - 1 }"
               @click="wizardAdvance()">
            Next Section <fluency-icon type="chevronRight" class="ml-2" />
          </div>
        </div>
        <div class="flex-grow-1 my-5">
          <template v-for="(section, sectionKey, sectionIndex) in filteredPersonas" :key="sectionKey">
            <template v-if="sectionIndex === wizard.step">
              <div v-for="(question, questionKey) in section"
                   :key="`${sectionKey}-${questionKey}`">
                <h3 v-if="questionKey === 0"
                    class="my-5 d-flex">
                  <span class="flex-grow-1">{{$filters.convertFromCamelCase(sectionKey)}}</span>
                  <span class="ml-3">[{{wizard.step + 1}} / {{personaObjectLength}}]</span>
                </h3>
                <hr />
                <div class="my-5">
                  <p><b>{{question.shortText}}</b></p>
                  <p>{{question.longText}}</p>
                  <div class="d-flex-center">
                    <label :for="`persona-wizard-${sectionKey}-${questionKey}`" class="mb-0">Enable?</label>
                    <b-checkbox :id="`persona-wizard-${sectionKey}-${questionKey}`"
                                :checked="checkboxCheckedStatus(sectionKey, question.key)"
                                switch
                                class="my-2"
                                @change="toggleValue($event, sectionKey, question.key)">
                    </b-checkbox>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <!-- NON-WIZARD VIEW -->
    <div v-if="wizard.init && !wizard.active" class="d-flex-center-center">
      <div class="list-view">
        <div v-for="(section, sectionKey) in filteredPersonas"
             :key="sectionKey"
             class="mb-5">
          <div v-for="(question, questionKey) in section"
               :key="questionKey">
            <h3 v-if="questionKey === 0"
                class="my-3">{{$filters.convertFromCamelCase(sectionKey)}}</h3>
            <div>
              <b-checkbox :checked="checkboxCheckedStatus(sectionKey, question.key)"
                          switch
                          class="my-2"
                          @change="toggleValue($event, sectionKey, question.key)">
                {{question.shortText}}
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import _cloneDeep from 'lodash.clonedeep'
import _intersection from 'lodash.intersection'

export default {
  name: 'userPersonaConfiguration',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      prefix: 'persona_',
      joiner: '_',
      workingObject: {},
      pendingChanges: false,
      changesSaved: false,
      verbiage: {
        toolname: 'Visibility Preferences',
        wizard: 'Guided Tour',
        nonWizard: 'Quick-Settings'
      },
      wizard: {
        init: false,
        active: true,
        step: 0
      },
      config: {
        notifications: [
          {
            key: 'viewRelevantToMyAccounts',
            shortText: 'See Alerts and Notifications Relevant to my Accounts',
            longText: 'We surface alerts and notifications for your accounts to make you aware of risks or configuration concerns with all accounts you\'re assigned to.'
          },
          {
            key: 'viewRecommendedActionsToMyAccounts',
            shortText: 'See Recommended Actions for My Accounts',
            longText: 'Fluency makes recommendations on how you can improve your account performance and configuration settings. Enabling this capability ensures you see Fluency Recommended Actions.'
          }
        ],
        manage: [
          {
            key: 'basic',
            shortText: 'Perform Basic Account and Campaign Management',
            longText: 'Our platform allows robust capabilities around management of campaign details across all your advertising accounts. This feature allows you to manage campaign budgets, settings, and ad content for the accounts in your portfolio.'
          },
          {
            key: 'bulkActions',
            shortText: 'Apply Portfolio Wide Changes and Bulk Actions',
            longText: 'The Bulk Action tool allows users to make many different types of changes changes across all accounts at once. This functionality supports granular changes that can have broad impact at scale.'
          },
          {
            key: 'budgetRecommendations',
            shortText: 'Get Budget Recommendations for Prospects and Existing Accounts',
            longText: 'Fluency can recommend budget adjustments for your accounts to drive improved performance. This feature displays the impact of changing spend on a per account basis to help inform spending decisions.'
          }
        ],
        reports: [
          {
            key: 'view',
            shortText: 'Design New Reports',
            longText: 'Our reporting solution allows users to create custom reports that include user selectable charts and graphs. Enable this feature to see the ability to design reports.'
          }
        ],
        blueprints: [
          {
            key: 'view',
            shortText: 'Design New Blueprints',
            longText: 'Blueprints enable launching and updating campaigns at scale. Advanced functionality supports limitless changes to ad campaigns based on inventory feeds, or data sources of your choosing. Enabling this feature allows you to design new and edit existing Blueprints for your organization.',
            capabilities: ['BlueprintEditor']
          }
        ],
        launch: [
          {
            key: 'view',
            shortText: 'Stage and Launch Accounts',
            longText: 'Used in conjunction with Blueprints, Account Staging and Launch features let you link accounts to Blueprints and launch campaigns for many accounts at once. Using this functionality is required to leverage the value Blueprints.'
          }
        ],
        settings: [
          {
            key: 'userAndGlobal',
            shortText: 'Administer Users and Global Settings',
            longText: 'Enabling this toggle will allow you to add, update, or delete users as well as display the Global Settings content for your organization.'
          },
          {
            key: 'developerLevel',
            shortText: 'Administer Developer Level Settings',
            longText: 'Some features in the Fluency platform are intended for a technical, developer-oriented audience. This toggle will show or hide those features.'
          }
        ]
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    userCapabilities () {
      return this.$store.getters.userCapabilities
    },
    savedPersonaValues () {
      const o = {}

      if (this?.userCapabilities) {
        Object.entries(this.userCapabilities).forEach(c => {
          const key = c[0]
          const val = c[1]

          if (key.startsWith(this.prefix)) {
            o[key] = val !== 'OFF'
          }
        })
      }

      return o
    },
    filteredPersonas () {
      const capabilities = this.user.capabilities || {}

      const o = {}

      Object.entries(this.config).forEach(con => {
        const key = con[0]
        const val = con[1]

        const filteredByCapabilities = val.filter(item => {
          let allMatches = true

          if (Array.isArray(item?.capabilities)) {
            item.capabilities.forEach(cap => {
              if (!!capabilities?.[cap] === false) {
                allMatches = false
              }
            })
          }

          return allMatches
        })

        if (filteredByCapabilities.length > 0) {
          o[key] = filteredByCapabilities
        }
      })

      return o
    },
    personaObjectLength () {
      return Object.keys(this.filteredPersonas).length
    }
  },
  watch: {
    savedPersonaValues: {
      handler (val) {
        this.$setCompat(this, 'workingObject', _cloneDeep(val))
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    init () {
      this.$setCompat(this.wizard, 'step', 0)
      this.$setCompat(this, 'pendingChanges', false)
      this.$setCompat(this, 'changesSaved', false)
    },
    checkboxCheckedStatus (sectionKey, questionKey) {
      return !this.workingObject.hasOwnProperty(this.getKey(sectionKey, questionKey)) || !!this.workingObject?.[this.getKey(sectionKey, questionKey)]
    },
    initWizard (val = true) {
      this.$setCompat(this.wizard, 'init', true)
      if (!val) {
        this.$setCompat(this.wizard, 'active', false)
      }
    },
    toggleWizard () {
      this.$setCompat(this.wizard, 'active', !this.wizard.active)
      this.$setCompat(this.wizard, 'step', 0)
    },
    wizardGoBack () {
      const newVal = (this.wizard.step === 0) ? 0 : this.wizard.step - 1
      this.$setCompat(this.wizard, 'step', newVal)
    },
    wizardAdvance () {
      const newVal = (this.wizard.step === (this.personaObjectLength - 1)) ? (this.personaObjectLength - 1) : this.wizard.step + 1
      this.$setCompat(this.wizard, 'step', newVal)
    },
    getKey (section, key) {
      return `${this.prefix}${section}${this.joiner}${key}`
    },
    async reloadUser () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          capabilities: response.capabilities
        }
        this.$store.commit('user', newUser)
      }
    },
    toggleValue (e, section, key) {
      this.$setCompat(this, 'pendingChanges', true)

      this.$setCompat(this.workingObject, this.getKey(section, key), e)
    },
    okModal () {
      this.save()
    },
    showModal () {
      this.init()
    },
    hideModal () {
      this.$emit('hide-modal')

      if (this.changesSaved) {
        window.location.reload()
      }
    },
    async save () {
      const savePromises = Object.entries(this.workingObject).map(o => {
        const key = o[0]
        const val = o[1]

        const saveObj = {
          type: 'PERSONA',
          key,
          value: (val) ? 'ON' : 'OFF'
        }

        return this.$res.set.userPreference(saveObj)
      })

      const responses = await Promise.all(savePromises)

      if (responses) {
        await this.reloadUser()
        this.$toast('Settings have been saved', 'success')
        this.$setCompat(this, 'pendingChanges', false)
        this.$setCompat(this, 'changesSaved', true)
        return
      }

      this.$toast('Unable to save settings', 'danger')
    }
  }
}
</script>

<style lang="scss" scoped>
  .wizard-intro {
    min-height: 400px;
    max-width: 600px;
  }

  .wizard-tour,
  .list-view {
    min-height: 400px;
    max-width: 700px;
  }

  .wizard-bck,
  .wizard-fwd {
    border-width: 2px;
    border-style: solid;

    &.disabled {
      opacity: 0.5;
      background-color: $fluency-lightergray !important;
      //background-color: transparent !important;
    }
  }
  .wizard-bck {
    border-color: $fluency-lightblue;
    background-color: rgba($fluency-lightblue, 0);

    &:hover {
      background-color: rgba($fluency-lightblue, 0.8);
    }
  }
  .wizard-fwd {
    border-color: $fluency-lightgreen;
    background-color: rgba($fluency-lightgreen, 0.4);

    &:hover {
      background-color: rgba($fluency-lightgreen, 0.8);
    }
  }
</style>
