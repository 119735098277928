<template>
  <div :class="[{'dropdown-menu highlight p-3': !nestedDropdown}, {'dropdown-menu-right': right}]">
    <div v-for="(topLevelItem, tliIndex) in definition"
         :key="tliIndex"
         class="mt-2 mb-4">

        <div class="section-title d-flex align-items-center mb-3">
          <fluency-icon :type="topLevelItem.icon" class="text-primary" />
          <div class="font-weight-bold pl-2 pr-4 bg-white text-primary">
            {{topLevelItem.text}}
          </div>
          <div class="flex-grow-1 border-top"></div>
        </div>

        <div v-for="(section, index) in topLevelItem.sections"
            :key="`${tliIndex}-${index}`"
            :class="['ml-4 muted-border', {'pt-2 mt-2 border-top': index > 0}]">
          <template v-for="(link, linkKey) in section" :key="`${tliIndex}-${linkKey}`">
            <a v-if="link.action"
               href="#"
               :class="['dropdown-item', {
                       'beta': link.beta
                     },
                     link.classes]"
               @click.prevent="doLinkAciton(link.action)">
              {{link.text}}
            </a>
            <nuxt-link v-else
                      :to="link.link"
                      :key="`link${tliIndex}-${linkKey}`"
                      :class="['dropdown-item', {
                      'active-page-link': activePage === link.text,
                      'beta': link.beta
                    },
                    link.classes]">
              {{link.text}}
            </nuxt-link>
          </template>
        </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'HeaderNavigationDropdown',
  props: {
    definition: {
      type: Array,
      default: () => []
    },
    activePage: {
      type: String,
      default: ''
    },
    right: {
      type: Boolean,
      default: false
    },
    nestedDropdown: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    capabilities () {
      return this.$store.getters.user?.capabilities || {}
    }
  },
  methods: {
    async doLinkAciton (action) {
      switch (action) {
        case 'download-sdf':

          await this.$res.customer.getSdf('customer')
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.active-page-link {
  background-color: #e9ecef;
}

.dropdown-item.beta {
  position: relative;
  padding-right: 23px;
  overflow: hidden;

  &:hover:after {
    background: rgba($fluency-red, 0.65);
  }

  &:after {
    content: 'beta';
    position: absolute;
    top: 4px;
    right: -25px;
    transform: rotate(45deg);
    padding: 0 38px;
    font-size: 11px;
    line-height: 14px;
    height: 16px;
    background: rgba($fluency-red, 0.5);
    color: white;
  }
}
</style>
