<template>
  <div class="d-flex-center">
    <b-checkbox :checked="checkboxVal"
                switch
                @change="userModeToggle($event)">Performance Bounds</b-checkbox>
  </div>

</template>

<script>
export default {
  name: 'GuardRailModeSwitcher',
  props: {
    variant: {
      type: String,
      default: 'success'
    }
  },
  data () {
    return {
      checkboxVal: null,
      backpackUserModeOptions: [
        { text: 'Off', value: false },
        { text: 'On', value: true }
      ]
    }
  },
  watch: {
    toggleValueBool: {
      handler (val) {
        this.$setCompat(this, 'checkboxVal', val)
      },
      immediate: true
    }
  },
  computed: {
    guardrails () {
      return this.$store.getters.setting('showGuardrails')
    },
    toggleValueBool () {
      return this.guardrails
    }
  },
  methods: {
    userModeToggle (e) {
      this.checkboxVal = e
      this.$store.commit('setting', { key: 'showGuardrails', value: !this.guardrails })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
