<template>
  <div>
    <span class="d-none" v-shortkey="['meta', 'shift', 'g']" @shortkey="handleShortKey()"></span>
    <b-modal v-model="modalOpen"
             id="urlModal"
             size="sm"
             body-class="overflow-visible"
             centered
             @show="handleShow()"
             @shown="selectText()"
             @hidden="handleHidden()"
             @ok="handleOk()">
      <template #modal-header>
        Where Would You Like To Go?
        <info-tooltip popover>
          <template #title>
            <div>We've added keyboard macros:</div>
            <ul>
              <li>a = /manage/account/?id=</li>
              <li>c = /manage/campaign/?id=</li>
              <li>b = /manage/budget/?id=</li>
              <li>ag = /manage/adgroup/?id=</li>
              <li>cr = /manage/ad/?id=</li>
              <li>kw = /manage/keyword/?id=</li>
            </ul>
          </template>
        </info-tooltip>
      </template>
      <b-form-group :label="host" label-cols="4">
        <b-input-group>
          <b-form-input :value="path"
                        autofocus
                        :placeholder="$route.fullPath"
                        ref="urlInput"
                        :debounce="300"
                        @keydown.enter="handleOk()"
                        @update="handleInput($event)"></b-form-input>
          <b-input-group-append>
            <copy-to-clipboard :val="`${origin}${$route.fullPath}`"
                               text="Copy"
                               variant="outline-secondary"
                               btn-style="border-bottom-left-radius: 0; border-top-left-radius: 0" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <b-form-group v-if="showUserSwitcher" label="Acting As" label-cols="4">
        <user-customer-selector :acting-as-user="actingAsUser"
                                :switch-immediately="false"
                                size=""
                                ref="switcher"
                                @change="actingAsUser = $event"></user-customer-selector>
      </b-form-group>
      <b-form-group v-if="local" label="API Environment" label-cols="4">
        <b-form-radio-group :checked="fsEnv" @input="fsEnv = $event" :options="envOptions"></b-form-radio-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import CopyToClipboard from '@/components/common/copyToClipboard'
import UserCustomerSelector from '@/components/common/userCustomerSelector'
import InfoTooltip from '~/components/common/forms/infoTooltip'
export default {
  name: 'UrlModal',
  components: { InfoTooltip, UserCustomerSelector, CopyToClipboard },
  data () {
    return {
      modalOpen: false,
      path: '',
      actingAsUser: undefined,
      fsEnv: this.$route.query.fsEnv,
      envOptions: ['staging', 'canary', 'local'],
      managePaths: {
        a: '/manage/account/?id=',
        c: '/manage/campaign/?id=',
        b: '/manage/budget/?id=',
        ag: '/manage/adgroup/?id=',
        cr: '/manage/ad/?id=',
        kw: '/manage/keyword/?id='
      }
    }
  },
  computed: {
    origin () {
      if (window) {
        return window.location.origin
      }
      return ''
    },
    host () {
      if (window) {
        return window.location.host
      }
      return ''
    },
    local () {
      return this.origin.includes('localhost')
    },
    showUserSwitcher () {
      const user = this.$store.getters.user || {}
      return user?.allowableCustomers && user?.allowableCustomers.length > 0 && user?.capabilities?.CustomerSwitcher
    }
  },
  methods: {
    async getCustomerFromUrl (path) {
      if (this.showUserSwitcher) {
        if (path) {
          const customer = await this.$res.fetch.getCustomerFromUrl(`${this.origin}${path}`)
          if (customer && customer.customerId) {
            this.actingAsUser = customer.customerId
          }
        }
      }
    },
    setActingAsUserFromStore () {
      const aau = this.$store.getters.actingAsUser || null
      if (aau !== this.actingAsUser) {
        this.$setCompat(this, 'actingAsUser', aau)
      }
    },
    handleHidden () {
      this.$setCompat(this, 'actingAsUser', undefined)
      this.path = this.$route.fullPath
    },
    handleShow () {
      this.setActingAsUserFromStore()
      this.path = this.$route.fullPath
    },
    handleShortKey () {
      this.modalOpen = true
    },
    handleInput (val) {
      this.path = this.formatInput(val)
      this.getCustomerFromUrl(val)
    },
    async handleOk () {
      if (this.path) {
        if (this.fsEnv && !this.path.includes(this.fsEnv)) {
          this.path = `${this.path}${this.path.includes('?') ? '&' : '?'}fsEnv=${this.fsEnv}`
        }
        if (this.actingAsUser !== (this.$store.getters.actingAsUser || null) && this.$refs.switcher) {
          let url
          if (this.path) {
            url = `${this.origin}${this.path}`
          }
          this.$refs.switcher.switchCustomer(this.actingAsUser, true, url)
        } else {
          await this.$router.push(this.path)
          this.$bvModal.hide('urlModal')
          this.path = ''
        }
      }
    },
    formatInput (input) {
      if (!input) return ''
      if (['a', 'c', 'b', 'ag', 'cr', 'kw'].includes(input)) {
        return this.managePaths[input]
      }
      if (input[0] === '/') {
        return input
      } else {
        if (input.indexOf('//') > 0) {
          const afterProtocol = input.substring(input.indexOf('//') + 2)
          return afterProtocol.substring(afterProtocol.indexOf('/'))
        } else if (input.indexOf('/') < 0) {
          return `/${input}`
        } else {
          return input.substring(input.indexOf('/'))
        }
      }
    },
    navigateBack () {
      if (history) {
        history.back()
      }
    },
    navigateForward () {
      if (history) {
        history.forward()
      }
    },
    selectText () {
      if (this.$refs.urlInput) {
        this.$refs.urlInput.select()
      }
    }
  }
}
</script>
